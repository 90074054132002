<template>
  <el-dialog
    title="员工信息导入"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-row class="excelRow">
        <el-col>
          <span>导入Excel文件</span>
        </el-col>
      </el-row>
      <div class="tablePart">
        <el-form-item label="公司" prop="companyId" class="fullList">
          <el-select
            v-model="dataForm.companyId"
            filterable
            @change="refreshLevelList"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="fullListBtn">
          <el-upload
            class="upload-demo"
            ref="upload"
            action="#"
            :limit="1"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="avatarUpload"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <el-input v-model="dataForm.fileName" disabled placeholder="" />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        companyId: null,
        fileName: null,
      },
      getFile: null,
      companyList: [],
      dataRule: {
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getCompany();
  },
  methods: {
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.$refs.upload.clearFiles();
        this.dataForm.companyId = null;
        this.dataForm.fileName = null;
        this.getFile = null;
      });
    },
    refreshLevelList(data) {
      console.log(data);
    },
    // 表单提交
    dataFormSubmit() {
      const Files = new FormData();
      let url = `/tc/employees/importEmployees`;
      Files.append('file', this.getFile);
      Files.append('fileName', this.dataForm.fileName);
      Files.append('companyId', this.dataForm.companyId);
      // 添加请求头
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http.post(url, Files, config).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('success');
                },
              });
            } else if (data && data.code === 500) {
              this.$message({
                message: data.msg,
                duration: 1500,
              });
            }
          });
        }
      });
    },

    getCompany() {
      this.$http({
        url: '/tc/company/combo',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        } else {
          this.companyList = [];
        }
      });
    },

    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isLt10M;
    },
    //上传文件
    avatarUpload({ file }) {
      console.log(file);
      this.getFile = null;
      const data = new FormData();
      data.append('file', file);
      this.dataForm.fileName = file.name;
      this.getFile = file;
    },
  },
};
</script>
