<template>
  <el-dialog
    :title="
      !dataForm.id ? '新增员工' : !disabled ? '修改员工信息' : '查看员工信息'
    "
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="公司" prop="companyId">
          <el-select
            v-model="dataForm.companyId"
            filterable
            :disabled="disabled"
            @change="refreshLevelList(dataForm.companyId)"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="员工工号" prop="workId">
          <el-input
            v-model="dataForm.workId"
            :disabled="disabled"
            placeholder="员工工号"
          />
        </el-form-item>
        <el-form-item label="员工级别" prop="nameAndType">
          <el-select
            v-model="dataForm.nameAndType"
            filterable
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in levelList"
              :key="index"
              :label="item.nameAndType"
              :value="item.nameAndType"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="员工姓名"
          />
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="dataForm.gender">
            <el-option
              v-for="item in genderOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            v-model="dataForm.phone"
            :disabled="disabled"
            placeholder="手机号码"
          />
        </el-form-item>
        <el-form-item label="余额" prop="balance" v-if="disabled">
          <el-input
            v-model="dataForm.balance"
            :disabled="disabled"
            placeholder="余额"
            v-if="disabled"
          />
        </el-form-item>

        <el-form-item label="是否可转补助" prop="isTransferBalance">
          <el-radio-group v-model="dataForm.isTransferBalance">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否绑定时段" prop="isBindingPeriod">
          <el-radio-group v-model="dataForm.isBindingPeriod">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      disabled: false,
      dataForm: {
        id: 0,
        userId: null,
        companyId: null,
        workId: null,
        levelId: null,
        levelName: null,
        name: null,
        gender: null,
        phone: null,
        subsidyId: null,
        subsidyType: null,
        subsidyName: null,
        nameAndType: null,
        balance: null,
        isTransferBalance: 0,
        isBindingPeriod: 1,
      },
      levelList: [],
      subsidyList: [],
      companyList: [],
      genderOptions: [
        { id: 1, name: '男' },
        { id: 2, name: '女' },
        { id: 0, name: '其他' },
      ],
      dataRule: {
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        nameAndType: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        subsidyId: [
          { required: true, message: '补助形式不能为空', trigger: 'blur' },
        ],
        workId: [
          { required: true, message: '员工工号不能为空', trigger: 'blur' },
        ],
        phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.$init({
        before: () => {
          this.getParentCompanyId();
          this.getCompany();
          this.getSubsidyList();
          this.getLevelList(this.dataForm.companyId);
          this.disabled = disabled;
        },
        url: id ? `/tc/employees/info/${id}` : null,
        after: (data) => {
          if (data && data.code === 0) {
            this.dataForm = { ...data.employees };
          }
        },
      });
    },
    refreshLevelList(id) {
      this.levelList = null;
      this.dataForm.nameAndType = null;
      this.getRefreshLevelList(id);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/employees/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              id: this.dataForm.id || undefined,
              // userId: this.dataForm.userId,
              companyId: this.dataForm.companyId,
              workId: this.dataForm.workId,
              name: this.dataForm.name,
              levelId: this.dataForm.levelId,
              levelName: this.dataForm.levelName,
              gender: this.dataForm.gender,
              phone: this.dataForm.phone,
              balance: this.dataForm.balance,
              nameAndType: this.dataForm.nameAndType,
              isTransferBalance: this.dataForm.isTransferBalance,
              isBindingPeriod: this.dataForm.isBindingPeriod,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('success');
                },
              });
            }
          });
        }
      });
    },

    getLevelList(id) {
      this.$http({
        url: `/tc/companylevel/combo/${id}`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.getParentCompanyId();
          this.levelList = data.list;
        }
      });
    },
    getRefreshLevelList(id) {
      this.$http({
        url: `/tc/companylevel/combo/${id}`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.levelList = data.list;
        }
      });
    },
    getSubsidyList() {
      this.$http({
        url: `/tc/subsidy/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.getParentCompanyId();
          this.subsidyList = data.list;
        }
      });
    },

    getCompany() {
      this.$http({
        url: '/tc/company/combo',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.getParentCompanyId();
          /*if (data.list[0].parentId === null) {
            this.disabled = false;
          }*/
          this.companyList = data.list;
        } else {
          this.companyList = [];
        }
      });
    },

    getParentCompanyId() {
      this.dataForm.companyId = this.$parent.companyId;
    },
  },
};
</script>
