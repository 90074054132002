<template>
  <div class="warehouse-list-base-page">
    <div class="feature-form-container">
      <el-form :inline="true" :model="dataForm" ref="dataForm">
        <div class="search-form-container">
          <div>
            <el-form-item>
              <el-input
                v-model="dataForm.workId"
                placeholder="工号"
                size="mini"
                clearable
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-input
                v-model="dataForm.name"
                placeholder="姓名"
                size="mini"
                clearable
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-input
                v-model="dataForm.levelName"
                placeholder="员工级别"
                size="mini"
                clearable
              />
            </el-form-item>
          </div>
          <div>
            <el-button @click="getSerialList(null, 1)" size="mini"
              >查询</el-button
            >
            <el-button
              v-if="isAuth('tc:employees:save')"
              type="primary"
              size="mini"
              @click="$dialog('addOrUpdate')"
              >新增员工信息</el-button
            >
            <el-button
              v-if="isAuth('tc:employees:update')"
              type="info"
              size="mini"
              :disabled="!(serialListSelections.length === 1)"
              @click="$dialog('addOrUpdate', serialListSelections[0].id)"
              >修改员工信息</el-button
            >
            <el-button
              v-if="isAuth('tc:employees:info')"
              type="success"
              size="mini"
              :disabled="!(serialListSelections.length === 1)"
              @click="$dialog('addOrUpdate', serialListSelections[0].id, true)"
              >查看员工信息</el-button
            >
            <el-button
              v-if="isAuth('tc:employees:delete')"
              type="danger"
              size="mini"
              :disabled="serialListSelections.length <= 0"
              @click="deleteHandle()"
              >删除员工信息</el-button
            >
            <el-button
              v-if="isAuth('tc:employees:importEmployees')"
              type="primary"
              @click="$dialog('employeesImport')"
              >导入员工信息</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <div class="goods-wrap">
      <div class="goods-detail-container leftSideWay">
        <div class="detail-container">
          <div class="table-container">
            <el-table
              :data="dataList"
              ref="dataList"
              border
              :row-class-name="tableRowClassName"
              @selection-change="$selectionChange($event, 'dataList')"
              @row-click="$clickRow($event, 'dataList', getDetailList($event))"
              @select="$select(arguments, 'dataList')"
              @select-all="$clearSelection('dataList')"
            >
              <el-table-column
                type="selection"
                header-align="center"
                align="center"
                width="50"
              />
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="公司列表"
              />
            </el-table>
          </div>
          <div class="pagination-container">
            <el-pagination
              background
              @size-change="$sizeChange($event, 'dataList')"
              @current-change="$currentChange($event, 'dataList')"
              :current-page="dataListIndex"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="dataListSize"
              :total="dataListCount"
              layout="total, prev, pager, next"
            />
          </div>
        </div>
        <div class="divider-border" />
        <div class="serial-container">
          <div class="table-container">
            <el-table
              :data="detailList"
              ref="detailList"
              border
              stripe
              @selection-change="$selectionChange($event, 'detailList')"
              @row-click="
                $clickRow($event, 'detailList', getSerialList($event))
              "
              @select="$select(arguments, 'detailList')"
              @select-all="$clearSelection('detailList')"
            >
              <el-table-column
                type="selection"
                header-align="center"
                align="center"
                width="50"
              />
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="公司列表"
              />
            </el-table>
          </div>
          <div class="pagination-container">
            <el-pagination
              @size-change="$sizeChange($event, 'detailList')"
              @current-change="$currentChange($event, 'detailList')"
              :current-page="detailListIndex"
              :page-sizes="[10, 20, 50]"
              :page-size="detailListSize"
              :total="detailListCount"
              layout="total, prev, pager, next"
            />
          </div>
        </div>
      </div>
      <div class="divider-border" />
      <div class="goods-list-container rightSideWay">
        <div class="table-container">
          <div class="listBtn">
            <span
              >剩余补助总数:<b>{{ this.totalList.employeesBalance }}</b></span
            >
            <span
              >剩余余额总数:<b>{{ this.totalList.employeesMoney }}</b></span
            >
            <span
              >按天补助总金额:<b>{{ this.totalList.dayBalance }}</b></span
            >
            <span
              >按月补助总金额:<b>{{ this.totalList.monthBalance }}</b></span
            >
          </div>
          <el-table
            :data="serialList"
            ref="serialList"
            border
            stripe
            v-loading="serialListLoading"
            @row-click="$clickRow($event, 'serialList')"
            @selection-change="$selectionChange($event, 'serialList')"
            style="width: 100%"
          >
            <el-table-column
              type="selection"
              header-align="center"
              align="center"
              width="50"
            />
            <el-table-column
              prop="workId"
              header-align="center"
              align="center"
              label="工号"
            />
            <!--          <el-table-column-->
            <!--            prop="userId"-->
            <!--            header-align="center"-->
            <!--            align="center"-->
            <!--            label="用户账号"-->
            <!--          />-->
            <el-table-column
              prop="levelName"
              header-align="center"
              align="center"
              label="员工级别"
            />
            <el-table-column
              prop="amount"
              header-align="center"
              align="center"
              label="补助等级金额"
            />
            <el-table-column
              prop="name"
              header-align="center"
              align="center"
              label="姓名"
            />
            <el-table-column
              prop="gender"
              header-align="center"
              align="center"
              label="性别"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.gender == 1" type="success" size="mini"
                  >男</el-tag
                >
                <el-tag
                  v-else-if="scope.row.gender == 2"
                  type="danger"
                  size="mini"
                  >女</el-tag
                >
                <el-tag v-else type="info" size="mini">未知</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="phone"
              header-align="center"
              align="center"
              width="160"
              label="手机号码"
            />
            <el-table-column
              prop="subsidyName"
              header-align="center"
              align="center"
              label="补助形式"
            >
              <!--<template slot-scope="scope">
                <el-tag v-if="scope.row.subsidyType === 1" type="info" size="mini"
                  >按天补助</el-tag
                >
                <el-tag v-else-if="scope.row.subsidyType == 2" size="mini"
                  >按月补助</el-tag
                >
              </template>-->
            </el-table-column>
            <el-table-column
              prop="balance"
              header-align="center"
              align="center"
              label="补助"
            />
            <el-table-column
              prop="money"
              header-align="center"
              align="center"
              label="余额"
            />
            <el-table-column
              prop="todayBalance"
              header-align="center"
              align="center"
              label="今天补助额度"
            />
            <el-table-column
              prop="tomorrowBalance"
              header-align="center"
              align="center"
              label="明天补助额度"
            />
            <el-table-column
              prop="isTransferBalance"
              header-align="center"
              align="center"
              label="是否可转补助"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.isTransferBalance === 0"
                  type="success"
                  size="mini"
                  >是</el-tag
                >
                <el-tag
                  v-if="scope.row.isTransferBalance === 1"
                  type="warning"
                  size="mini"
                  >否</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column
              prop="isBindingPeriod"
              header-align="center"
              align="center"
              label="是否绑定时段"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.isBindingPeriod === 0"
                  type="success"
                  size="mini"
                  >是</el-tag
                >
                <el-tag
                  v-if="scope.row.isBindingPeriod === 1"
                  type="warning"
                  size="mini"
                  >否</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            @size-change="$sizeChange($event, 'serialList')"
            @current-change="$currentChange($event, 'serialList')"
            :current-page="serialListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="serialListSize"
            :total="serialListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getSerialList"
    />
    <employees-import
      v-if="employeesImportVisible"
      ref="employeesImport"
      @success="getSerialList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './employees-add-or-update';
import EmployeesImport from './employees-import';
import { threeListMixin, normal } from '@/mixins';

export default {
  mixins: [threeListMixin, normal],
  data() {
    return {
      dataForm: {
        name: '',
        workId: '',
        levelName: '',
      },
      totalList: {
        employeesBalance: '',
        employeesMoney: '',
        dayBalance: '',
        monthBalance: '',
      },
      companyId: '',
      addOrUpdateVisible: false,
      employeesImportVisible: false,
    };
  },

  //注册需要引入的组件，即一个vue页面，这里指employees-add-or-update页面，
  components: {
    //要注册的文件首字母大写
    AddOrUpdate,
    EmployeesImport,
  },

  activated() {
    this.getDataList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      // if (row.isUse === 0) return 'danger-row';
    },
    //获取数据列表
    getDataList() {
      this.$api({
        url: '/tc/company/list',
        params: {
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.list.records;
            if (data && data.list.records?.length) {
              this.companyId = this.dataList[0].id;
              // this.getDetailList(data.list.records[0]);
              this.dataListCount = data.list.total;
              // this.getSerialList(data.list.records[0].id);
              this.$nextTick(() => {
                this.$refs.dataList.toggleRowSelection(
                  data.list.records[0],
                  true,
                );
              });
            }
          } else {
            this.dataList = [];
          }
        },
      });
    },
    // 获取数据列表
    getDetailList(row) {
      this.$api({
        url: '/tc/company/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          companyIds: row.id,
        },
        after: (data) => {
          if (data && data.code === 0) {
            if (data && data.list.records?.length) {
              this.detailList = data.list.records;
              this.detailListCount = data.list.total;
              this.getSerialList(data.list.records[0]);
              this.companyId = data.list.records[0].id;
              this.$nextTick(() => {
                this.$refs.detailList.toggleRowSelection(
                  data.list.records[0],
                  true,
                );
              });
            } else {
              this.detailList = [];
              this.detailListCount = 0;
            }
          } else {
            this.detailList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    getSerialList(row, isclear) {
      if (isclear !== 1) {
        (this.dataForm.name = null), (this.dataForm.workId = null);
        this.dataForm.levelName = null;
      }
      this.companyId = row && row.id ? row.id : this.companyId;
      this.$api({
        url: '/tc/employees/list',
        params: {
          page: this.serialListIndex,
          limit: this.serialListSize,
          companyId: this.companyId,
          levelName: this.dataForm.levelName,
          workId: this.dataForm.workId,
          name: this.dataForm.name,
        },
        after: (data) => {
          this.totalList = data.total;
          if (data && data.code === 0) {
            if (data && data.page.records?.length) {
              this.serialList = data.page.records;
              this.serialListCount = data.page.total;
            } else {
              this.serialList = [];
              this.serialListCount = 0;
            }
          } else {
            this.serialList = [];
            this.serialListCount = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.serialListSelections.map((item) => {
            return item.id;
          });
      console.log(ids);
      this.$handleDelete({
        tip: `是否确认对这些数据进行删除操作?`,
        url: '/tc/employees/delete',
        data: ids,
        after: () => {
          this.getSerialList();
        },
      });
    },
  },
};
</script>

<style>
/* element滚动条组件 隐藏水平滚动条 */
.sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
/*.is-horizontal {*/
/*  display: none;*/
/*}*/
/*.el-row {*/
/*  margin-bottom: 20px;*/
/*&:last-child {*/
/*   margin-bottom: 0;*/
/* }*/
/*}*/
/*.el-col {*/
/*  border-radius: 4px;*/
/*}*/
/*.bg-purple-dark {*/
/*  background: #99a9bf;*/
/*}*/
.bg-purple {
  background: #d3dce6;
}
/*.bg-purple-light {*/
/*  background: #e5e9f2;*/
/*}*/
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/*.row-bg {*/
/*  padding: 10px 0;*/
/*  background-color: #f9fafc;*/
/*}*/
</style>
